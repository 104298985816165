<template>
  <div class="box">
    <div class="home">
      <van-swipe :show-indicators="false" class="my-swipe" :autoplay="3000">
        <van-swipe-item
          ><img src="./../assets/images/lunbo.png"
        /></van-swipe-item>
        <van-swipe-item
          ><img src="./../assets/images/lunbo.png"
        /></van-swipe-item>
      </van-swipe>
      <div class="content">
        <p class="p1">
          Lorem ipsum dolor sit amet, consectetur elit, sed empor incididunt ut
          labore e magna aliqu Quis ipsdisse ultrices commodo viverra maecenas
          accumsan lacus vel facilisis ndisse ultrices viverra maecenas.
        </p>
        <p class="p2">
          Lorem ipsum dolor sit amet, consectetur elit, sed dsmod tempor
          incididunt ut labore e magna aliqu Quispendisse ultrices commodo
          viverra maecenaslacus vel facilisis.
        </p>
        <p class="p3">
          Lorem ipsum dolor sit amet, consectetur elit, sed dsmod tempor
          incididunt ut labore e magna aliqu Quispendisse ultrices commodo
          viverra maecenaslacus veecenaslacus vel facilisis.
        </p>
      </div>
      <div class="start-btn" @click="startEvent">开始</div>
    </div>
  </div>
</template>
<script>
import{ AES } from "../plugins/md5"
export default {
  data() {
    return {
      ThirdPartyProjectId: ''
    }
  },
  watch: {
    $route: {
      handler: function (newVal) {
        console.log(newVal);
        if (newVal.name == 'Home') {
          localStorage.removeItem('ThirdPartyProjectId')
          if (newVal.query.id) {
              localStorage.setItem('ThirdPartyProjectId', newVal.query.id)
          }else{
            //  localStorage.setItem('ThirdPartyProjectId',"48304413-727a-43db-adc4-0c25ab852cb1")
            //  localStorage.setItem('ThirdPartyProjectId',"48eb538f-c10d-4f02-8031-94e2e6572efa")
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.ThirdPartyProjectId = localStorage.getItem('ThirdPartyProjectId')   
    if( !sessionStorage.getItem("SESSIONTOKEN")){
      signIn() 
    }
  },
  methods: {
    startEvent() {
       this.$router.push({
        name: 'Product',
        params: {
          id: this.ThirdPartyProjectId
        }
      })
      for (const key in sessionStorage) {
        if(key.indexOf('windows.net-accesstoken')!==-1){
          let sessiontoken = AES.encrypt('Bearer '+JSON.parse(sessionStorage[key]).secret).toString()
          sessionStorage.setItem("SESSIONTOKEN",sessiontoken ) 
        }
      }
   
    }
    
  }
}
</script>
<style scoped >
.box {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.home {
  padding: 30px;
}

.p1,
.p2,
.p3 {
  font-size: 26px;
  margin-top: 29px;

  color: #000000;
}

.p1 {
  margin-top: 20px;
}

.start-btn {
  width: 690px;
  height: 85px;
  background: #ec6602;
  border: 1px solid #ffffff;
  border-radius: 43px;
  margin-top: 54px;
  text-align: center;
  font-size: 36px;
  color: #ffffff;
  line-height: 85px;
}

.my-swipe {
  width: 100%;
  height: 440px;
  overflow: hidden;
  border-radius: 10px;
}

img {
  width: 100%;
  height: 100%;
}
</style>